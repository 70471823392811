import Vue from 'vue';
import _ from 'lodash';
import { ApiResponse, ApiError } from "@/services/ApiResponse";

export default class HttpProxy {
  static async submit({ method, url, data, parameters, headers }) {
    try {
      const response = await Vue.$http({
        method: method,
        url: url + this.getParameterString(parameters),
        data: data,
        headers: headers
      });
      return new ApiResponse(response.status, response.statusText, response.data);
    }
    catch(error) {
      throw new ApiError(
        error.response.status,
        error.response.statusText,
        error.response.data
          ? error.response.data.error.message
          : 'Error api call'
      );
    }
  }

  static getParameterString(parameters) {
    if(!parameters || _.isEmpty(parameters))
      return '';
    const parameterStrings = Object
      .keys(parameters)
      .filter(key => !!parameters[key])
      .map(key => `${key}=${parameters[key]}`);
    return parameterStrings.length === 0 ? '' : `?${parameterStrings.join('&')}`;
  }
}
