import Vue from 'vue';
import { Http } from '@/services/Http';

const _http = new Http();

Vue.$http = _http;
Object.defineProperty(Vue.prototype, '$http', {
  get() {
    return _http;
  },
});
