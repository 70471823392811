import axios from 'axios';
import SignService from '@/services/SignService';
import router from '@/router';
import { LOCAL_STORAGE_SAMII_KEYS, getItem } from '@/utils/storage';

export class Http {
  constructor () {
    return this.buildAxiosInstance();
  }

  buildAxiosInstance() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_API_LOCATION}/`,
      withCredentials: false, 
    });
    this.axiosInstance.defaults.headers.common.Accept = 'application/json';
    this.setRequestInterceptors();
    this.setResponseInterceptors();
    return this.axiosInstance;
  }

  setRequestInterceptors() {
    this.axiosInstance.interceptors.request.use(
      (request) => {
        const token = getItem(LOCAL_STORAGE_SAMII_KEYS.TOKEN);
        request.headers.authorization = token ? `Bearer ${token}` : '';
        //  if (AuthService.isAccessTokenExpired() && AuthService.hasRefreshToken()) {
        //    return AuthService.debounceRefreshTokens()
        //      .then(response => {
        //        AuthService.setBearer(response.data.accessToken)
        //        request.headers.authorization = AuthService.getBearer()
        //        return request
        //      }).catch(error => Promise.reject(error))
        //  } else {
        //    return request
        //  }
        return request;
      },
      (error) => {
          return Promise.reject(error);
      });
  }

  setResponseInterceptors() {
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          SignService.logout();
          router.push({ name: 'login.index' });
        }
        return Promise.reject(error);
      });
  }
}
