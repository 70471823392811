export const ACCOUNT_STORE = 'account/store';
export const ACCOUNT_REPLACE_CURRENCY = 'account/replaceCurrency';
export const ACCOUNT_REPLACE_MEMBERSHIP = 'account/replaceMembership';
export const ACCOUNT_REPLACE_MEMBERSHIP_ABOUT_TO_END = 'account/replaceMembershipAboutToEnd';
export const ACCOUNT_REPLACE_PERMITS = 'account/replacePermits';
export const ACCOUNT_DESTROY = 'account/destroy';

export default {
  ACCOUNT_STORE,
  ACCOUNT_REPLACE_CURRENCY,
  ACCOUNT_REPLACE_MEMBERSHIP,
  ACCOUNT_REPLACE_MEMBERSHIP_ABOUT_TO_END,
  ACCOUNT_REPLACE_PERMITS,
  ACCOUNT_DESTROY,
};
