import {
  INVOICES_STORE,
  INVOICES_UPDATE,
  INVOICES_DESTROY,
} from './mutation-types';

export const store = ({ commit }, data) => {
  commit(INVOICES_STORE, data);
};

export const update = ({ commit }, data) => {
  commit(INVOICES_UPDATE, data);
};

export const destroy = ({ commit }) => {
  commit(INVOICES_DESTROY);
};

export default {
  store,
  update,
  destroy,
};
