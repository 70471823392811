import {
  CATALOGS_STORE,
  CATALOGS_DESTROY,
} from './mutation-types';

export default {
  [CATALOGS_STORE](state, data) {
    state.flags = data.flags;
    state.paymentMethods = data.paymentMethods;
    state.orderStatuses = data.orderStatuses;
    state.businessTaxes = data.businessTaxes;
  },
  [CATALOGS_DESTROY](state) {
    state.flags = [];
    state.paymentMethods = [];
    state.orderStatuses = [];
    state.businessTaxes = [];
  },
};
