export const CHECKLIST_ITEMS_STORE = 'checklistItems/store';
export const CHECKLIST_ITEMS_CREATE = 'checklistItems/create';
export const CHECKLIST_ITEMS_UPDATE = 'checklistItems/update';
export const CHECKLIST_ITEMS_DELETE = 'checklistItems/delete';
export const CHECKLIST_ITEMS_DESTROY = 'checklistItems/destroy';

export default {
  CHECKLIST_ITEMS_STORE,
  CHECKLIST_ITEMS_CREATE,
  CHECKLIST_ITEMS_UPDATE,
  CHECKLIST_ITEMS_DELETE,
  CHECKLIST_ITEMS_DESTROY,
};
