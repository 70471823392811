import store from '@/store';
import SignService from '@/services/SignService';

export default async function checkAuthAccount({ next, router, to }) {
  if(!store.state.auth.authenticated && to.name != 'login.index')
    return router.push({ name: 'login.index' });
  if (store.state.auth.authenticated && !store.state.account.id)
    await SignService.loadSignedAccount();
  return next();
}
