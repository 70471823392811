<template>
  <div
    class="membership-about-to-end bg-purple-500"
    v-if="$store.state.account.membershipAboutToEnd && display">
    <div class="btn-close cursor-pointer">
      <i @click="close(false)" class="text-xl bx bx-x"></i>
    </div>
    <span>{{ $store.state.account.membershipAboutToEnd.replace('haz click aquí.', '') }}</span>
    <a class="cursor-pointer" style="text-decoration: underline" @click="close(true)">haz click aquí.</a>
  </div>
</template>
<style scoped>
.btn-close {
  position: absolute;
  top: 0;
  right: 0;
}
.membership-about-to-end {
  margin-top: 8px;
  margin-left: 25%;
  width: 50%;
  height: 40px;
  color: white;
  top: 0;
  z-index: 999;
  text-align: center;
  position: fixed;
  border-radius: 4px;
  padding: 8px;
}
</style>
<script>
import { ACCOUNT_REPLACE_MEMBERSHIP_ABOUT_TO_END } from '@/store/modules/account/mutation-types';

export default {
  name: 'MembershipAboutToEnd',
  computed: {
    display() {
      return !this.$route.path.includes('invoices');
    },
  },
  methods: {
    close(redirect) {
      this.$store.dispatch(
        ACCOUNT_REPLACE_MEMBERSHIP_ABOUT_TO_END, { membershipAboutToEnd: false },
      );
      if (redirect)
        this.$router.push({ name: 'admin.index', params: { slot: 'my-membership' } });
    },
  },
}
</script>
