import HttpProxy from '@/services/HttpProxy';

export default class CouponsService {
  static async check(parameters) {
    const response = await HttpProxy.submit({
      method: 'get', url: 'Coupons', parameters,
    });
    return response?.data ?? null;
  }

  static async getAccountCoupons(isAnnual) {
    const all = isAnnual === undefined;
    const response = await HttpProxy.submit({
      method: 'get',
      url: all ? 'Account/Coupons' : `Account/Coupons?isAnnual=${isAnnual}`,
    });
    return response?.data ?? null;
  }
}
