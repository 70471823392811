<template>
  <div>
    <button
      :disabled="disabled"
      :class="[ buttonType, buttonSize, customClass, buttonDisabled ]"
      @click="$emit('click')">
      <div>
        <slot>Button</slot>
      </div>
    </button>
  </div>
</template>
<style scoped>
.sa-button-size-mini {
  @apply px-4 py-1.5;
}
.sa-button-size-base {
  @apply px-4 py-2;
}
.sa-button-text {
  @apply text-purple-500 hover:text-purple-400;
}
.sa-button-primary {
  @apply rounded font-bold shadow border text-white bg-purple-500 border-purple-500 hover:border-purple-600 hover:bg-purple-600;
}
.sa-button-secondary {
  @apply rounded font-bold shadow border text-gray-500 bg-white border-gray-300 hover:bg-gray-100;
}
.sa-button-disabled {
  @apply rounded font-bold shadow border text-gray-500 bg-gray-100 border-gray-300 hover:bg-gray-100 hover:border-gray-300;
  cursor: not-allowed;
  text-decoration: line-through;
}
</style>
<script>
export default {
  name: 'SaButton',
  props: {
    type: {
      type: String,
      default: () => 'primary',
    },
    size: {
      type: String,
      default: () => 'base',
    },
    customClass: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    buttonType() {
      return `sa-button-${this.type.toLowerCase()}`;
    },
    buttonSize() {
      return this.type.toLowerCase() === 'text'
        ? ''
        : `sa-button-size-${this.size.toLowerCase()}`;
    },
    buttonDisabled() {
      return this.disabled ? 'sa-button-disabled' : '';
    },
  },
};
</script>
