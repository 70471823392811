import checkAuthInPublic from '@/router/middlewares/checkAuthInPublic';

export default [
  {
    path: '/',
    name: 'landing.index',
    component: () => import('@/views/public/landing/Index.vue'),
    meta: { }
  },
  {
    path: '/terms',
    name: 'terms.index',
    component: () => import('@/views/public/terms/TermsConditions.vue'),
    meta: { }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy.index',
    component: () => import('@/views/public/policies/PrivacyPolicy.vue'),
    meta: { }
  }, {
    path: '/delete-account',
    name: 'delete-account.index',
    component: () => import('@/views/public/delete-account/DeleteAccount.vue'),
    meta: { }
  },
  {
    path: '/login',
    name: 'login.index',
    component: () => import('@/views/public/login/LoginIndex.vue'),
    meta: {
      middlewares: [ checkAuthInPublic ]
    }
  },
  {
    path: '/register/:plan?',
    name: 'register.index',
    component: () => import('@/views/public/register/RegisterIndex.vue'),
    meta: {
      middlewares: [ checkAuthInPublic ]
    }
  },
  {
    path: '/invite/:businessId/:businessName/:email/:accountType',
    name: 'invite.index',
    component: () => import('@/views/public/invite/InviteIndex.vue'),
    meta: {
      middlewares: [ checkAuthInPublic ]
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot.index',
    component: () => import('@/views/public/login/ForgotIndex.vue'),
    meta: {
      middlewares: [ checkAuthInPublic ]
    },
  },
  {
    path: '/revision-account/:token',
    name: 'reset-password.index',
    component: () => import('@/views/public/login/ResetIndex.vue'),
    meta: {
      middlewares: [ checkAuthInPublic ]
    }
  },
  {
    path: '/my-order-status/:id',
    name: 'my-order-status.index',
    component: () => import('@/views/public/my-order-status/MyOrderStatusIndex.vue'),
    meta: {
      middlewares: [ checkAuthInPublic ]
    }
  },
  {
    path: '/verification/:token',
    name: 'verification.index',
    component: () => import('@/views/public/verification/VerificationIndex.vue'),
    meta: {
      middlewares: [ checkAuthInPublic ]
    }
  },
  {
    path: '/resend-verification-email',
    name: 'resend-verification-email.index',
    component: () => import('@/views/public/verification/ResendVerificationIndex.vue'),
    meta: {
      middlewares: [ checkAuthInPublic ]
    }
  }
];
