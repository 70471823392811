import {
  INVOICES_STORE,
  INVOICES_UPDATE,
  INVOICES_DESTROY,
} from './mutation-types';

const mapper = (config) => Object.keys(config).reduce((acc, key) => {
  const {
    id,
    logoWidth,
    logoHeight,
    canViewLogo,
    canViewBusinessName,
    canViewPasscode,
  } = config[key] || {
    id: undefined,
    logoWidth: '100',
    logoHeight: '100',
    canViewLogo: true,
    canViewBusinessName: true,
    canViewPasscode: false,
  };

  return {
    ...acc,
    [key]: {
      id,
      showLogo: canViewLogo,
      logoX: logoWidth,
      logoY: logoHeight,
      showBusinessName: canViewBusinessName,
      showPasscode: canViewPasscode,
    }
  }
}, {});

export default {
  [INVOICES_STORE](state, data) {
    state.config = mapper(data.config);
  },
  [INVOICES_UPDATE](state, data) {
    state.config = mapper(data.config);
  },
  [INVOICES_DESTROY](state) {
    state.config = {};
  },
};
