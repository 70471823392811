<template>
  <div class="px-4 py-4 bg-white border border-white rounded shadow" :class="customClass">
    <div class="flex flex-row mb-2" :class="itemsCentered ? 'items-center' : 'text-left'">
      <div class="w-full font-bold text-md">
        <slot name="title">
          {{ title }}
        </slot>
        <div class="description">
          <slot name="description">
            {{ description }}
          </slot>
        </div>
      </div>
      <div class="ml-auto self-start">
        <slot name="actions" />
      </div>
    </div>
    <div :class="slotCustomClass">
      <slot />
    </div>
  </div>
</template>
<style scoped>
.description {
  font-weight: 400;
  font-size: small;
  color: gray;
}
</style>
<script>
export default {
  name: 'ContentCard',
  props: {
    title: {
      type: String,
      default: () => null
    },
    customClass: {
      type: String,
      default: () => null
    },
    slotCustomClass: {
      type: String,
      default: () => "flex flex-col"
    },
    itemsCentered: {
      type: Boolean,
      default: () => true
    },
    description: {
      type: String,
      default: () => null
    }
  },
}
</script>
