import _ from "lodash";

export const LOCAL_STORAGE_SAMII_KEYS = {
  MAIN: 'SAMIIWEB_LOCAL_STORAGE',
  TOKEN: 'TOKEN',
  QUICK_START_STATUS: 'QUICK_START_STATUS',
  DATE_FORMAT_STATUS: 'DATE_FORMAT_STATUS',
};

const getMain = () => {
  const DefaultSamiiwebStorageValues = {
    [LOCAL_STORAGE_SAMII_KEYS.TOKEN]: null,
    [LOCAL_STORAGE_SAMII_KEYS.QUICK_START_STATUS]: 'done',
    [LOCAL_STORAGE_SAMII_KEYS.DATE_FORMAT_STATUS]: 'undone'
  };

  try {
    const main = JSON.parse(localStorage.getItem(LOCAL_STORAGE_SAMII_KEYS.MAIN));

    if (_.isNil(main) || _.isEmpty(main)) {
      return DefaultSamiiwebStorageValues;
    }

    return {
      ...DefaultSamiiwebStorageValues,
      ...main
    };
  } catch (e) {
    return DefaultSamiiwebStorageValues;
  }
};

const checkAvailableKey = (key) => {
  const availableKeys = Object.keys(LOCAL_STORAGE_SAMII_KEYS);

  return availableKeys.includes(key);
}

export const getItem = (key) => {
  if (checkAvailableKey(key)) {
    const main = getMain();

    return main[key];
  }
};

export const setItem = (key, value) => {
  if (checkAvailableKey(key)) {
    const main = getMain();

    localStorage.setItem(LOCAL_STORAGE_SAMII_KEYS.MAIN, JSON.stringify({
      ...main,
      [key]: value,
    }));
  }
};

export const removeItem = (key, defaultValue = null) => {
  if (checkAvailableKey(key)) {
    const main = getMain();

    localStorage.setItem(LOCAL_STORAGE_SAMII_KEYS.MAIN, JSON.stringify({
      ...main,
      [key]: defaultValue,
    }));
  }
}

export default {
  LOCAL_STORAGE_SAMII_KEYS,
  getItem,
  setItem,
  removeItem,
};
