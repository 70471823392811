import Vue from 'vue';
import QrcodeVue from "qrcode.vue";
import CountryFlag from 'vue-country-flag';
import SaButton from '@/components/atoms/SaButton.vue';
import SaIconButton from '@/components/atoms/SaIconButton.vue';
import SaCategory from '@/components/atoms/SaCategory.vue';
import SaCheckbox from '@/components/atoms/SaCheckbox.vue';
import SaPhoneCountry from '@/components/atoms/SaPhoneCountry.vue';
import SaEditor from '@/components/atoms/SaEditor.vue';
import SaActionButton from '@/components/atoms/SaActionButton.vue';
import SaGroupActionButton from '@/components/atoms/SaGroupActionButton.vue';

import Payment from '@/components/dialogs/Payment';
import Service from '@/components/dialogs/Service';
import OrderNotesManager from '@/components/dialogs/OrderNotesManager';
import Category from '@/components/dialogs/Category';
import OrderPayment from '@/components/dialogs/OrderPayment';

import MembershipAboutToEnd from '@/components/alerts/MembershipAboutToEnd';

import LockScroll from '@/components/molecules/LockScroll';
import LockScreen from '@/components/molecules/LockScreen';
import QuickStartTip from '@/components/molecules/QuickStartTip';

Vue.component('qr-code', QrcodeVue);
Vue.component('country-flag', CountryFlag);
Vue.component('sa-button', SaButton);
Vue.component('sa-icon-button', SaIconButton);
Vue.component('sa-category', SaCategory);
Vue.component('sa-checkbox', SaCheckbox);
Vue.component('sa-phone-country', SaPhoneCountry);
Vue.component('sa-editor', SaEditor);
Vue.component('sa-action-button', SaActionButton);
Vue.component('sa-group-action-button', SaGroupActionButton);

Vue.component('payment', Payment);
Vue.component('service', Service);
Vue.component('order-notes-manager', OrderNotesManager);
Vue.component('category', Category);
Vue.component('order-payment', OrderPayment);

Vue.component('membership-about-to-end', MembershipAboutToEnd);
Vue.component('lock-scroll', LockScroll);
Vue.component('lock-screen', LockScreen);
Vue.component('quick-start-tip', QuickStartTip);
