<template>
  <el-dialog
    :visible.sync="visible"
    :width="width"
    @close="close">
    <div class="w-full py-4">
      <div class="w-full flex flex-row items-center mb-4">
        <strong class="text-lg">Notas</strong>
        <a class="text-purple-400 ml-auto" @click="addNote = true">
          <strong>+ Agregar nueva nota</strong>
        </a>
      </div>
      <content-card v-if="addNote" v-loading.fullscreen.lock="loading" class="mb-4">
        <note @onCancel="addNote = false" @onSave="create" />
      </content-card>
      <div class="w-full" style="max-height: 50vh; overflow-y: auto">
        <content-card
          v-for="note in notes"
          :key="note.id"
          class="mb-4">
          <div class="w-full" v-if="!editingNotes[note.id]">
            <div class="font-bold" style="text-transform: capitalize;">{{ dateNote(note) }}</div>
            <div>{{ note.note }}</div>
            <div class="w-full flex flex-row" style="justify-content: right;">
              <div class="flex flex-row mr-4" style="align-items: center;">
                <strong class="mr-1">Privada</strong>
                <el-switch v-model="note.isPrivate" disabled />
              </div>
              <sa-button
                class="rounded text-bold mr-2"
                custom-class="w-full"
                type="secondary"
                @click="confirmDelete(note.id)">
                Eliminar
              </sa-button>
              <sa-button
                class="rounded text-bold"
                custom-class="w-full"
                type="primary"
                @click="addEditing(note)">
                Editar
              </sa-button>
              <el-button
                class="ml-2 el-button--success"
                @click="whatsapp(note)">
                WhatsApp
              </el-button>
            </div>
          </div>
          <div class="w-full" v-if="editingNotes[note.id]">
            <note :note="editingNotes[note.id]" @onCancel="removeEditing(note.id)" @onSave="update" />
          </div>
        </content-card>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import _ from 'lodash';
import service from './service';
import error from '@/mixins/error';
import responsiveSize from '@/mixins/responsiveSize';
import OrderService from '@/services/OrderService';
import { Whatsapp, getCountryCode } from '@/utils/phones';

export default {
  name: 'OrderNotesManager',
  mixins: [error, responsiveSize],
  components: {
    ContentCard: () => import('@/components/molecules/ContentCard'),
    Note: () => import('./Note'),
  },
  data() {
    return {
      width: '40%',
      visible: false,
      orderId: null,
      addNote: false,
      reference: null,
      notes: [],
      client: {},
      editingNotes: {},
      loading: false,
      errorMessage: null,
      subscription: null,
    }
  },
  created() {
    this.subscription = service.subscribe(this.open);
    window.addEventListener('resize', _.debounce(this.resize, 300));
    this.resize();
  },
  destroyed() {
    this.subscription.unsubscribe();
    window.removeEventListener('resize', _.debounce(this.resize, 300));
  },
  watch: {
    'errorMessage': {
      deep: true,
      handler: _.debounce(async function () {
        if (this.errorMessage) {
          await this.$toastr.e(this.errorMessage);
          this.errorMessage = undefined;
        }
      }, 300)
    },
  },
  methods: {
    resize() {
      const percentageWidth = this.getPercentageWidth();
      this.width = percentageWidth === '30%' ? '40%' : percentageWidth;
    },
    open(payload) {
      this.visible = true;
      this.orderId = payload;
      this.load();
    },
    close() {
      this.visible = false;
      this.notes = [];
      this.editingNotes = {};
    },
    dateNote(note) {
      return this.$moment(note.updatedAt || note.createdAt).format('dddd, MMMM Do YYYY, h:mm:ss a');
    },
    whatsapp(note) {
      const countryCode = getCountryCode(
        this.$store.state.catalogs.flags,
        this.client.phoneCountryCodeId,
      );
      if (!countryCode) {
        this.$toastr.e('El Cliente debe tener registrado el Código de Area');
        return;
      }
      Whatsapp({
        code: countryCode,
        phone: this.client.phone,
        text: `Hay una nueva nota en tu orden ${this.reference}. "${note.note}"`,
      });
      OrderService.addHistoryWhatsapp({
        orderId: this.orderId,
        message: `Hay una nueva nota en tu orden ${this.reference}. "${note.note}"`,
      });
    },
    addEditing(note) {
      this.editingNotes = {
        ...this.editingNotes,
        [note.id]: note,
      };
    },
    removeEditing(noteId) {
      const newEditingNotes = { ...this.editingNotes };
      delete newEditingNotes[noteId];
      this.editingNotes = newEditingNotes;
    },
    async load() {
      this.loading = true;
      try {
        const order = await OrderService.getById(this.orderId);
        this.reference = order?.reference || null;
        this.notes = order?.notes || [];
        this.client = order?.client || {};
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
    async create({ value, isPrivate }) {
      this.loading = true;
      try {
        const data = { note: value, orderId: this.orderId, isPrivate };
        const note = await OrderService.createNote(data);
        this.addNote = false;
        this.notes.push(note);
        this.$toastr.s('Se creo con exito');
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
    async update({ id, value, isPrivate }) {
      this.loading = true;
      try {
        await OrderService.updateNote({ id, note: value, isPrivate });
        this.removeEditing(id);
        this.notes = this.notes.map((note) => note.id === id ? { id, note: value } : note);
        this.$toastr.s('Se actualizo con exito');
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
    async confirmDelete(noteId) {
      this.$confirm(`¿Está seguro que desea eliminar esta nota?`, {
        type: 'warning',
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancelar',
      }).then(async () => {
        await this.onDelete(noteId);
      });
    },
    async onDelete(noteId) {
      this.loading = true;
      try {
        await OrderService.deleteNote(noteId);
        this.$toastr.s('Se borro con exito');
        this.notes = this.notes.filter((note) => note.id !== noteId);
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>
