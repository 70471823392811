import HttpProxy from '@/services/HttpProxy';

export default class CategoryService {
  static Product = {
    getById: async (id) => {
      const response = await HttpProxy.submit({
        method: 'get',
        url: `ProductCategories/${id}`,
        parameters: { id },
      });
      return response?.data ?? null;
    },
    get: async (parameters) => {
      const response = await HttpProxy.submit({
        method: 'get',
        url: 'ProductCategories',
        parameters,
      });
      return response?.data ?? null;
    },
    post: async (data) => {
      const response = await HttpProxy.submit({
        method: 'post',
        url: 'ProductCategories',
        data,
      });
      return response?.data ?? null;
    },
    delete: async (id) => {
      const response = await HttpProxy.submit({
        method: 'delete',
        url: `ProductCategories/${id}`,
        data: { id },
      });
      return response?.data ?? null;
    },
  };

  static Service = {
    getById: async (id) => {
      const response = await HttpProxy.submit({
        method: 'get',
        url: `ServiceCategories/${id}`,
        parameters: { id },
      });
      return response?.data ?? null;
    },
    get: async (parameters) => {
      const response = await HttpProxy.submit({
        method: 'get',
        url: 'ServiceCategories',
        parameters,
      });
      return response?.data ?? null;
    },
    post: async (data) => {
      const response = await HttpProxy.submit({
        method: 'post',
        url: 'ServiceCategories',
        data,
      });
      return response?.data ?? null;
    },
    put: async (data) => {
      const response = await HttpProxy.submit({
        method: 'put',
        url: `ServiceCategories/${data.id}`,
        data: {
          name: data.name,
        }, 
      });
      return response?.data ?? null;
    },
    delete: async (id) => {
      const response = await HttpProxy.submit({
        method: 'delete',
        url: `ServiceCategories/${id}`,
        data: { id },
      });
      return response?.data ?? null;
    },
  };

  static Device = {
    post: async (data) => {
      const response = await HttpProxy.submit({
        method: 'post',
        url: 'DeviceType',
        data,
      });
      return response?.data ?? null;
    },
  };
}
