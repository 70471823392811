import {
  NOTIFICATIONS_STORE,
  NOTIFICATIONS_DESTROY,
  NOTIFICATIONS_MARK_AS_READ,
  NOTIFICATIONS_DELETE,
} from './mutation-types';

export default {
  [NOTIFICATIONS_STORE](state, data) {
    state.entities = data;
  },
  [NOTIFICATIONS_MARK_AS_READ](state, data) {
    state.entities = state.entities.map((entity) => entity.id === data ? { ...entity, read: true } : entity);
  },
  [NOTIFICATIONS_DELETE](state, data) {
    state.entities = state.entities.filter((entity) => !data.includes(entity.id));
  },
  [NOTIFICATIONS_DESTROY](state) {
    state.entities = [];
  },
};
