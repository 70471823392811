import * as types from './mutation-types';

export const store = ({ commit }, data) => {
  commit(types.CHECKLIST_ITEMS_STORE, data);
};

export const create = ({ commit }, data) => {
  commit(types.CHECKLIST_ITEMS_CREATE, data);
};

export const update = ({ commit }, data) => {
  commit(types.CHECKLIST_ITEMS_UPDATE, data);
};

export const remove = ({ commit }, data) => {
  commit(types.CHECKLIST_ITEMS_DELETE, data);
};

export const destroy = ({ commit }) => {
  commit(types.CHECKLIST_ITEMS_DESTROY);
};

export default {
  store,
  create,
  update,
  remove,
  destroy,
};
