import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

import '@/plugins/axios';
import '@/plugins/element-ui';
import '@/plugins/samii-ui';

import '@/assets/element-ui/theme.scss';
import '@/assets/tailwind/main.scss';
import '@/assets/samii.scss';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false;

store.dispatch('auth/check');

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

let fixElSelect = function () {
	document.querySelectorAll('.el-select[data-is-search="true"]:hover').forEach(() => {
		let elInput = document.querySelector('.el-select[data-is-search="true"]:hover input[readonly]');
		if (elInput) {
			elInput.readOnly = false;
			elInput.blur();
			elInput.focus();
		}
	});
};
document.addEventListener("focusin", fixElSelect);
document.addEventListener("click", fixElSelect);
document.addEventListener("touchstart", fixElSelect);