import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.js';

const fullConfig = resolveConfig(tailwindConfig);

export default {
  methods: {
    getPercentageWidth() {
      if(this.isThresholdMD())
        return '90%';
      else if(this.isThresholdLG())
        return '50%';
      else if(this.isThresholdXL())
        return '40%';
      else if(this.isThreshold2XL())
        return '30%';
    },
    isThresholdMD() {
      return window.innerWidth <= fullConfig.theme.screens.md.replace('px', '');
    },
    isThresholdLG() {
      return window.innerWidth > fullConfig.theme.screens.md.replace('px', '')
        && window.innerWidth <= fullConfig.theme.screens.lg.replace('px', '');
    },
    isThresholdXL() {
      return window.innerWidth > fullConfig.theme.screens.md.replace('px', '')
        && window.innerWidth <= fullConfig.theme.screens.lg.replace('px', '');
    },
    isThreshold2XL() {
      return window.innerWidth > fullConfig.theme.screens.xl.replace('px', '');
    },
  },
};
