<template>
  <div :class="{ 'lock-scroll': locked }">
    <slot></slot>
  </div>
</template>
<style>
.lock-scroll {
  overflow: hidden;
}
</style>
<script>
export default {
  name: 'LockScroll',
  props: {
    locked: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    locked(isLocked) {
      document.body.style.overflow = isLocked ? 'hidden' : '';
    },
  },
};
</script>
