import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition)
      return savedPosition
    return { x: 0, y: 0 };
  },
});

const nextFactory = async (context, middleware, index) => {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware)
    return context.next;
  return async (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = await nextFactory(context, middleware, index + 1);
    await subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach(async (to, from, next) => {
  if (to.meta.middlewares) {
    const middleware = to.meta.middlewares;
    const context = { from, next, router, to, };
    const nextMiddleware = await nextFactory(context, middleware, 1);
    await middleware[0]({ ...context, next: nextMiddleware });
  }
  next();
});

export default router;
