import { ApiError } from '@/services/ApiResponse';
export default {
  methods: {
    getErrorMessage(error) {
      if (error instanceof ApiError)
        return error.message;
      return 'Error desconocido. Por favor, vuelva a intentarlo.';
    },
  },
}
