import * as types from "./mutation-types";

export const store = ({ commit }, data) => {
  commit(types.QUICK_START_STORE, data);
};

export const destroy = ({ commit }) => {
  commit(types.QUICK_START_DESTROY);
};

export default {
  store,
  destroy,
};
