import { Subject } from 'rxjs';

export const Service = () => {
    const subject = new Subject();

    const subscribe = (process) => subject.subscribe(process);

    const show = (payload) => {
      subject.next(payload);
    };

    return {
      subscribe,
      show,
    };
}

export const Command = () => {
  const subject = new Subject();

  const subscribe = (process) => subject.subscribe(process);

  const onCommand = (type, payload) => {
    subject.next({ type, payload })
  };

  return {
    subscribe,
    onCommand,
  }
};
