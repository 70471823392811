<template>
  <div>
    <vue-editor
      v-model="local"
      :editorToolbar="editorToolbar"
    ></vue-editor>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";

export default {
  name: 'SaEditor',
  components: {
    VueEditor
  },
  props: {
    content: {
      type: String,
      default: () => null
    }
  },
  computed: {
    local: {
      get() {
        return this.content;
      },
      set(value) {
        this.$emit('update:content', value);
      }
    }
  },
  methods: {
    update(value) {
      this.local = value;
      this.$emit('update:content', value);
    }
  },
  data() {
    return {
      editorToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6]
          }
        ],
        ["bold", "italic", "underline", "strike"],
        ["blockquote"],
        [
          {
            list: "ordered"
          },
          {
            list: "bullet"
          },
        ],
        [
          {
            color: []
          },
          {
            background: []
          }
        ],
        ["link", "image"],
        ["clean"],
      ],
    }
  }
}
</script>