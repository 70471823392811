<template>
  <div class="w-full flex flex-row justify-evenly text-center">
    <sa-action-button
      v-if="showEdit"
      :disabled="disableEdit"
      title="Editar"
      icon="el-icon-edit"
      type="primary"
      @click="onEdit()" />
    <sa-action-button
      v-if="showDelete"
      :disabled="disableDelete"
      title="Eliminar"
      icon="el-icon-delete"
      type="danger"
      @click="onDelete()" />
    <sa-action-button
      v-if="showSave"
      title="Guardar"
      icon="bx-save"
      @click="onSave" />
    <sa-action-bitton
      v-if="showCancel"
      title="Cancelar"
      icon="bx-x"
      type="danger"
      @click="onCancel" />
  </div>
</template>
<script>
export default {
  name: 'SaGroupActionButton',
  props: {
    showEdit: {
      type: Boolean,
      default: () => true,
    },
    disableEdit: {
      type: Boolean,
      default: () => false,
    },
    showDelete: {
      type: Boolean,
      default: () => true,
    },
    disableDelete: {
      type: Boolean,
      default: () => false,
    },
    showSave: {
      type: Boolean,
      default: () => false,
    },
    showCancel: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    onEdit() {
      this.$emit('edit');
    },
    onDelete() {
      this.$emit('delete');
    },
    onSave() {
      this.$emit('save');
    },
    onCancel() {
      this.$emit('cancel');
    },
  }
}
</script>