<template>
  <div>
    <el-tooltip effect="dark" :content="tooltip" :disabled="!tooltip">
      <button
        class="p-1 px-2 rounded cursor-pointer hover:bg-gray-200"
        :class="[`hover:text-${hoverColor}-500`, withBg ? `bg-${bgColor}` : '', `text-${iconColor}`]"
        @click="$emit('click', $event)">
        <i
          class="mt-1 text-xl"
          :class="iconClass">
        </i>
      </button>
    </el-tooltip>
  </div>
</template>
<script>
export default { 
  name: 'SaIconButton',
  props: {
    tooltip: {
      type: String,
      default: () => null
    },
    iconClass: {
      type: String,
      default: () => 'bx'
    },
    hoverColor: {
      type: String,
      default: () => 'gray'
    },
    bgColor: {
      type: String,
      default: () => 'gray-100'
    },
    iconColor: {
      type: String,
      default: () => 'gray-600'
    },
    withBg: {
      type: Boolean,
      default: () => false
    }
  },
}
</script>
