import HttpProxy from '@/services/HttpProxy';
import NotificationService from '@/services/NotificationService';

export default class OrderService {
  static async getAll(parameters) {
    const response = await HttpProxy.submit({
      method: 'get',
      url: 'orders',
      parameters,
    });
    return response?.data ?? null;
  }

  static async getById(id) {
    const response = await HttpProxy.submit({ method: 'get', url: `orders/${id}` });
    return response?.data ?? null;
  }

  static async create(data) {
    const response = await HttpProxy.submit({ method: 'post', url: 'orders', data: data });
    await NotificationService.get();
    return response?.data ?? null;
  }

  static async update(data) {
    const response = await HttpProxy.submit({ method: 'put', url: `orders/${data.id}`, data: data });
    await NotificationService.get();
    return response?.data ?? null;
  }

  static async updateStatus(data) {
    const response = await HttpProxy.submit({
      method: 'put', url: `Orders/status/${data.id}`, data: { statusId: data.status, paymentMethodId: data.paymentMethod,  endDate: data.endDate},
    });
    return response?.data ?? null;
  }

  static async deleteOrder(id) {
    const response = await HttpProxy.submit({ method: 'delete', url: `Orders/${id}`});
    return response?.data ?? null;
  }

  static async applyWarranty(id) {
    const response = await HttpProxy.submit({ method: 'post', url: `Orders/apply-warranty/${id}`, data: { orderId: id } });
    return response?.data ?? null;
  }

  static async createNote(data) {
    const response = await HttpProxy.submit({ method: 'post', url: 'Orders/notes', data });
    return response?.data ?? null;
  }

  static async updateNote(data) {
    const response = await HttpProxy.submit({ method: 'put', url: `Orders/notes/${data.id}`, data });
    return response?.data ?? null;
  }

  static async deleteNote(id) {
    const response = await HttpProxy.submit({ method: 'delete', url: `Orders/notes/${id}` });
    return response?.data ?? null;
  }

  static async myOrderStatus(id) {
    const response = await HttpProxy.submit({ method: 'get', url: `Orders/get-my-status/${id}`});
    return response?.data ?? null;
  }

  static async getDeviceTypes(parameters) {
    const response = await HttpProxy.submit({ method: 'get', url: 'DeviceType', parameters });
    return response?.data ?? null;
  }

  static async getDailySales(parameters) {
    const response = await HttpProxy.submit({ method: 'get', url: 'Orders/get-daily-sales', parameters });
    return response?.data ?? null;
  }

  static async getPdfByOrderId(parameters) {
    const { orderId } = parameters;
    const response = await HttpProxy.submit({ method: 'get', url: `Orders/get-pdf-by-id/${orderId}`, parameters });
    return response?.data ?? null;
  }

  static async getFinances(parameters) {
    const response = await HttpProxy.submit({ method: 'get', url: 'Orders/FinanceDashboard', parameters });
    return response?.data ?? null;
  }

  static async getFinancesTotals(parameters) {
    const response = await HttpProxy.submit({ method: 'get', url: 'Orders/FinanceTotals', parameters });
    return response?.data ?? null;
  }

  static async getFinancesOrders(parameters) {
    const response = await HttpProxy.submit({ method: 'get', url: 'Orders/FinanceOrders', parameters });
    return response?.data ?? null;
  }

  static async getOrderHistory(id) {
    const response = await HttpProxy.submit({ method: 'get', url: `Orders/GetHistory/${id}`});
    return response?.data ?? null;
  }

  static async addHistoryWhatsapp(data) {
    const response = await HttpProxy.submit({ method: 'post', url: 'Orders/AddHistoryWhatsapp', data });
    return response?.data ?? null;
  }

  static async updateOrderSignature(id, signature) {
    const response = await HttpProxy.submit({ method: 'put', url: `Orders/signature/${id}`, data: { signature } });
    return response?.data ?? null;
  }
}
