export default {
  id: null,
  address: null,
  businessId: null,
  country: null,
  logo: null,
  name: null,
  phone: null,
  currency: null,
  timezone: null,
  phoneCountryCodeId: null,
  dateFormat: null,
}
