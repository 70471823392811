export class ApiResponse {
  constructor (status, statusText, data = null) {
    this.status = status;
    this.statusText = statusText;
    this.data = data ?? null;
  }
}

export class ApiError extends Error {
  constructor (statusError, statusText, message) {
    super();
    this.statusError = statusError;
    this.statusText = statusText;
    this.message = message;
  }
}
