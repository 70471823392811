<template>
    <div v-if="locked" class="overlay" />
</template>
<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(160, 160, 160, 0.5);
}
</style>
<script>
export default {
  name: 'LockScreen',
  props: {
    locked: {
      type: Boolean,
      default: false
    }
  }
};
</script>
