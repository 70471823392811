import * as types from "./mutation-types";

export const store = ({ commit }, data) => {
  commit(types.ACCOUNT_STORE, data);
};

export const replaceCurrency = ({ commit }, data) => {
  commit(types.ACCOUNT_REPLACE_CURRENCY, data);
};

export const replaceMembership = ({ commit }, data) => {
  commit(types.ACCOUNT_REPLACE_MEMBERSHIP, data);
};

export const replaceMembershipAboutToEnd = ({ commit }, data) => {
  commit(types.ACCOUNT_REPLACE_MEMBERSHIP_ABOUT_TO_END, data);
};

export const replacePermits = ({ commit }, data) => {
  commit(types.ACCOUNT_REPLACE_PERMITS, data);
};

export const destroy = ({ commit }) => {
  commit(types.ACCOUNT_DESTROY);
};

export default {
  store,
  replaceCurrency,
  replaceMembership,
  replaceMembershipAboutToEnd,
  replacePermits,
  destroy,
};
