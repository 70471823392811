<template>
  <el-dialog
    :visible.sync="visible"
    :width="width"
    @close="close">
    <div class="px-2">
      <div
        class="flex flex-row items-center">
        <span class="text-md font-bold">{{ title }}</span>
        <div class="ml-auto">
          <el-button class="bx bx-x" style="padding: 10px;" @click="close" />
        </div>
      </div>
      <el-form
        ref="categoryForm"
        :model="model"
        :rules="rules"
        v-loading="loading">
        <el-form-item prop="name">
          <strong>Nombre</strong>
          <el-input v-model="model.name" />
        </el-form-item>
      </el-form>
      <div class="flex justify-end flex-row space-x-4">
        <el-button class="el-button--default text-right" @click="close">
          Cancelar
        </el-button>
        <el-button class="el-button--primary" @click="submit">
          Guardar
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import _ from 'lodash';
import responsiveSize from '@/mixins/responsiveSize';
import error from '@/mixins/error';
import _service, { command as _command, commands } from './service';
import _string from './string';
import CategoryService from '@/services/CategoryService';

export default {
  name: 'Category',
  mixins: [responsiveSize, error],
  data() {
    return {
      type: undefined,
      width: '40%',
      visible: false,
      loading: false,
      subscription: undefined,
      errorMessage: undefined,
      model: {
        id: undefined,
        name: undefined,
      },
      rules: {
        name: [
          { required: true, message: 'Captura el nombre de la categoría' },
        ],
      },
    }
  },
  mounted() {
    this.subscription = _service.subscribe(this.open);
    window.addEventListener('resize', _.debounce(this.resize, 200));
  },
  destroyed() {
    this.subscription.unsubscribe();
    window.removeEventListener('resize', _.debounce(this.resize, 200));
  },
  watch: {
    'errorMessage': {
      deep: true,
      handler: _.debounce(async function () {
        if (this.errorMessage) {
          await this.$toastr.e(this.errorMessage);
          this.errorMessage = undefined;
        }
      }, 300)
    },
  },
  computed: {
    title() {
      if (this.model.id) {
        return `Actualizar categoria de ${_string[this.type]}`;
      }
      return `Agregar nueva categoria de ${_string[this.type]}`;
    },
  },
  methods: {
    resize() {
      const percentage = this.getPercentageWidth();
      this.width = percentage === '30%' ? '40%' : percentage;
    },
    close() {
      this.visible = false;
      this.type = undefined;
      this.model = { id: undefined, name: undefined };
    },
    open({ type, value = { id: undefined, name: undefined } }) {
      this.visible = true;
      this.type = type;
      this.model = { ...value };
      this.resize();
      this.$refs['categoryForm']?.clearValidate();
    },
    submit() {
      this.$refs['categoryForm'].validate(async (isValidForm) => {
        if (!isValidForm) return;
        if (this.model.id) await this.update();
        else await this.create();
      })
    },
    async create() {
      this.loading = true;
      try {
        const type = {
          1: 'Product',
          2: 'Service',
          3: 'Device',
        }[this.type];
        const category = await CategoryService[type].post({ name: this.model.name });
        _command.onCommand(commands.create, { id: category?.id, name: this.model.name, type: this.type });
        this.$toastr.s('Se creo con exito');
        this.close();
      } catch(e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      this.loading = true;
      try {
        const type = {
          1: 'Product',
          2: 'Service',
          3: 'Device',
        }[this.type];
        await CategoryService[type].put({ id: this.model.id, name: this.model.name });
        _command.onCommand(commands.update, this.model);
        this.$toastr.s('Se actualizo con exito');
        this.close();
      } catch(e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>
