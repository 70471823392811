import {
  QUICK_START_STORE,
  QUICK_START_DESTROY,
} from './mutation-types';

export default {
  [QUICK_START_STORE](state, data) {
    state.step = data.step;
  },
  [QUICK_START_DESTROY](state) {
    state.step = 0;
  }
};
