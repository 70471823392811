<template>
  <div id="app">
    <router-view />
    <membership-about-to-end />
    <payment />
    <service />
    <order-notes-manager />
    <category />
    <order-payment />
  </div>
</template>
<script>
import { MobileOperatingSystem, MobileAppRoute } from '@/constants';

export default {
  mounted() {
    this.runner();
    this.preventWheel();
  },
  methods: {
    getUserAgent() {
      return navigator.userAgent || navigator.vendor || window.opera;
    },
    getOs() {
      const userAgent = this.getUserAgent();

      if (/android/i.test(userAgent)) {
        return MobileOperatingSystem.Android;
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return MobileOperatingSystem.iOS;
      }

      return "unknown";
    },
    runner() {
      const userAgent = this.getUserAgent();
      const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(userAgent);

      if (isMobile) {
        const os = this.getOs();

        switch (os) {
          case MobileOperatingSystem.Android:
            this.askToRedirect(MobileAppRoute.Android);
            break;
          default:
        }
      }
    },
    askToRedirect(mobileAppRoute) {
      this.$confirm(
        `¡Descarga nuestra app y disfruta de una mejor experiencia! 📲 ¿Te animas? 😊`, {
          type: 'info',
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
        }).then(async () => {
          window.location.href = mobileAppRoute;
        });
    },
    preventWheel() {
      document.addEventListener(
        'wheel',
        function(event) {
          if (document.activeElement.type === 'number') {
            event.preventDefault();
          }
        },
        { passive: false },
      );
    },
  }
}
</script>