export const PhoneFormat = (number) => number
  ? number
    .replace(/[^0-9]/g, '')
    .replace(/^(\d{3})?(\d{3})?(\d{4})?/g, '$1 $2 $3')
    .substr(0, 12)
  : undefined;

export const PhoneSideFormat = (number) => number
  ? number
    .replace(/[^\d]/g, '')
    .substr(0, 3)
  : undefined;


export const getCountryCode = (countries, phoneCountryCodeId) => {
  const country = countries.find((c) => c.id === phoneCountryCodeId);
  if (!country) {
    return null;
  }
  return country.countryCode;
};


export const Whatsapp = ({ code, phone, text = null }) => {
  let url = process.env.VUE_APP_WHATSAPP_LINK;
  const number = `${code}${phone}`;
  if (number) url = `${url}?phone=${number}`;
  if (text) url = `${url}&text=${text}`;
  window.open(url, '_blank', 'noreferrer');
};
