import { Service } from '@/utils/dialogs';
import { Command } from '@/utils/dialogs';

const service = Service();

export const command = Command();

export const commands = {
  create: 'create',
  update: 'update',
};

export default service;
