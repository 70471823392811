<template>
  <div class="">
    <span v-if="label"
      class="inline-flex items-center justify-center flex-shrink-0">
      {{ label }}
    </span>
    <el-select
      class="w-full"
      :value="value"
      :disabled="disabled"
      :clearable="clearable"
      @change="onChange"
    >
      <el-option
        v-for="category in categories"
        :key="category.value"
        :label="category.label"
        :value="category.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { CategoryEnum } from "../../constants";

export default {
  name: 'SaCategory',
  props: {
    clearable: Boolean,
    label: String,
    value: Number,
    disabled: Boolean,
    onChange: Function,
  },
  data() {
    return {
      categories: [
        { value: CategoryEnum.Phone, label: 'Teléfono' },
        { value: CategoryEnum.Tablet, label: 'Tableta' },
        { value: CategoryEnum.Laptop, label: 'Computadora Portátil' },
        { value: CategoryEnum.Desktop, label: 'Computadora Escritorio' },
        { value: CategoryEnum.Wearable, label: 'Usable' },
      ],
    }
  },
}
</script>
