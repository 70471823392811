const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
  content: ['./public/**/*.html', './src/**/*.{vue,js,ts,jsx,tsx}'],
  purge: ['./index.html', './src/**/*.{vue,js,ts,jsx,tsx}'],
  theme: {
    fontFamily: {
      'sans': [ 'Lato', ...defaultTheme.fontFamily.sans ],
      // // 'serif': [ ],
      // // 'mono': [ ],
      'headline': [ 'Poppins' ]
    },
    fontSize: {
      'xs': '.75rem',
      'sm': '12px',
      'base': '14px',
      'md': '16px',
      'lg': '1.125rem',
      'xl': '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '4rem',
      '7xl': '5rem',
    },
    extend: {},
  },
  variants: {
    extend: {},
  },
  plugins: [],
};
