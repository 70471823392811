import * as types from './mutation-types';

export const store = ({ commit }, data) => {
  commit(types.NOTIFICATIONS_STORE, data);
};

export const markAsRead = ({ commit }, data) => {
  commit(types.NOTIFICATIONS_MARK_AS_READ, data);
};

export const destroy = ({ commit }) => {
  commit(types.NOTIFICATIONS_DESTROY);
};

export default {
  store,
  markAsRead,
  delete: ({ commit }, data) => {
    commit(types.NOTIFICATIONS_DELETE, data);
  },
  destroy,
};
