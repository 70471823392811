import { Command } from '@/utils/dialogs';

const command = Command();

export const Commands = {
  create: 'create',
  update: 'update',
};

export default command;
