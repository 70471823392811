// import Vue from 'vue';
import { AUTH_CHECK, AUTH_STORE, AUTH_LOGOUT } from './mutation-types';
import { LOCAL_STORAGE_SAMII_KEYS, getItem, setItem, removeItem } from '../../../utils/storage';

export default {
  [AUTH_CHECK](state) {
    state.authenticated = !!getItem(LOCAL_STORAGE_SAMII_KEYS.TOKEN);
  },

  [AUTH_STORE](state, data) {
    setItem(LOCAL_STORAGE_SAMII_KEYS.TOKEN, data.token);
    state.authenticated = true;
  },

  [AUTH_LOGOUT](state) {
    removeItem(LOCAL_STORAGE_SAMII_KEYS.TOKEN);
    state.authenticated = false;
  },
};
