<template>
  <el-dialog
    :visible.sync="visible"
    :width="width"
    v-loading.fullscreen.lock="loading">
    <div class="w-full flex flex-row" style="margin-bottom: 10px;">
      <h1 class="text-lg font-bold inline-flex items-center">
        <strong>&nbsp;Planes de Membresía</strong>
      </h1>
    </div>
    <el-steps :active="active" align-center style="margin-bottom: 10px;" >
      <el-step title="Productos" />
      <el-step title="Tarjetas" />
      <el-step title="Cupones" />
      <el-step title="Resumen" />
    </el-steps>
    <div v-if="active === tabs.products" class="w-full">
      <content-card title="Por favor selecciona un plan de membresia">
        <div class="w-full mb-4">
          <div
            class="w-full border rounded-md p-4 cursor-pointer hover:border-purple-400 my-2"
            :class="purchase.productId === product.id ? 'item-selected-primary' : ''"
            v-for="product in products"
            :key="product.id"
            @click="selectProduct(product)">
            <label class="text-xl font-bold ">{{ product.name }}</label>
            <div>{{ product.description }}</div>
            <div class="text-xl font-bold text-right">{{ moneyFormat(product.price / 100) }} USD</div>
          </div>
        </div>
        <div class="w-full flex flex-row mt-3 mb-4" style="justify-content: center;" v-if="false">
          <el-checkbox v-model="recurrent" @change="getProducts">
            Renueva la suscripción de manera automática.
          </el-checkbox>
        </div>
        <div class="w-full text-right">
          <el-button
            class="el-button--primary"
            @click="next"
            :disabled="!purchase.productId">
            Continuar
          </el-button>
        </div>
      </content-card>
    </div>
    <div v-if="active === tabs.cards" class="w-full">
      <content-card title="Por favor selecciona un metodo de pago">
        <div class="w-full flex flex-row mb-4" style="justify-content: space-between;">
          <span class="font-bold text-md">Tarjetas</span>
          <a v-if="!addCard" class="text-purple-400" @click="addCard = true">
            <strong>+ Agregar nueva tarjeta</strong>
          </a>
        </div>
        <div v-if="addCard" class="w-full mb-4">
          <div class="w-full flex flex-row">
            <stripe-element-card
              ref="stripeElementCardRef"
              :pk="publishableKey"
              @token="createCard"
              class="w-full mr-4"
            />
            <div class="flex flex-row text-center" style="align-items: center;">
              <div class="w-1/2">
                <button
                  title="Guardar"
                  class="el-button--primary rounded-full cursor-pointer mr-4"
                  @click="submitCard">
                  <i class="bx bx-save" style="padding: 4px; font-size: 20px;"></i>
                </button>
              </div>
              <div class="w-1/2">
                <button
                  title="Eliminar"
                  class="el-button--danger rounded-full cursor-pointer"
                  @click="addCard = false">
                  <i class="bx bx-x" style="padding: 4px; font-size: 20px;"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="w-full border rounded-md p-4 cursor-pointer hover:border-purple-400 my-2"
          :class="purchase.cardId === card.id ? 'item-selected-primary' : ''"
          v-for="card in cards"
          :key="card.id"
          @click="selectCard(card)">
            <span class="text-lg font-bold">XXXX-XXXX-XXXX-{{ card.last4 }}</span>

          <div class="w-full flex flex-row" style="justify-content: space-between;">
            <div class="text-lg">{{ card.brand }}</div>
            <div class="text-lg">{{ card.expMonth }} / {{ card.expYear }}</div>
          </div>
        </div>
        <div class="w-full text-right mt-4">
          <el-button
            type="primary" plain
            class="el-button--primary self-start"
            @click="prev">
            Atras
          </el-button>
          <el-button
            class="el-button--primary self-end"
            @click="next"
            :disabled="!purchase.cardId">
            Continuar
          </el-button>
        </div>
      </content-card>
    </div>
    <div v-if="active === tabs.promotionCodes" class="w-full">
      <content-card title="Puedes seleccionar un Código de Promoción">
        <div class="w-full flex flex-row mb-4" style="justify-content: space-between;">
          <span class="font-bold text-md">Códigos de Promoción</span>
          <a class="text-purple-400" @click="canAddPromotionCode()">
            <strong>+ Agregar nuevo código de promoción</strong>
          </a>
        </div>
        <div v-if="addPromotionCode" class="w-full mb-4">
          <div class="w-full flex flex-row">
            <el-input v-model="newPromotionCode" />
            <div class="flex flex-row text-center ml-2" style="align-items: center;">
              <div class="w-1/2">
                <button
                  title="Guardar"
                  class="el-button--primary rounded-full cursor-pointer mr-4"
                  @click="createPromotionCode">
                  <i class="bx bx-save" style="padding: 4px; font-size: 20px;"></i>
                </button>
              </div>
              <div class="w-1/2">
                <button
                  title="Eliminar"
                  class="el-button--danger rounded-full cursor-pointer"
                  @click="addPromotionCode = false; newPromotionCode = null;">
                  <i class="bx bx-x" style="padding: 4px; font-size: 20px;"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full mb-4">
          <div
            class="w-full border rounded-md p-4 cursor-pointer"
            :class="{
              'item-selected-primary': purchase.promotionCodeId === promotionCode.id,
              'cursor-not-allowed': promotionCode.isUsed,
              'bg-gray-200': promotionCode.isUsed,
              'hover:border-purple-400': !promotionCode.isUsed,
            }"
            v-for="promotionCode in promotionCodes"
            :key="promotionCode.id"
            @click="selectPromotionCode(promotionCode)">
            <label
              class="text-xl font-bold"
              :class="{ 'cursor-not-allowed': promotionCode.isUsed }"
            >{{ promotionCode.code}}</label>
            <br>
            <label v-if="promotionCode.isAnnual" class="text-md">*Descuento al primer mes</label>
            <div v-if="promotionCode.isPercent" class="text-xl font-bold text-right">{{ promotionCode.percentOff }} %</div>
            <div v-if="!promotionCode.isPercent" class="text-xl font-bold text-right">{{ moneyFormat(promotionCode.amountOff) }}</div>
            <div v-if="promotionCode.isUsed" class="text-xl font-bold text-right text-red-400">Usado</div>
          </div>
        </div>
        <div class="w-full text-right">
          <el-button
            type="primary" plain
            class="self-start"
            @click="prev">
            Atras
          </el-button>
          <el-button
            class="el-button--primary"
            @click="next">
            Continuar
          </el-button>
        </div>
      </content-card>
    </div>
    <div v-if="active === tabs.resume" class="w-full">
      <content-card title="Resumen de compra">
        <div class="pt-4 mb-4">
          <div class="w-full flex flex-row border-b mb-2">
            <span class="w-1/3 font-bold">Membresia:</span>
            <div class="w-2/3 text-right text-md font-bold">{{ _product.name }}</div>
          </div>
          <div class="w-full flex flex-row border-b mb-2">
            <span class="w-1/3 font-bold">Descripcion:</span>
            <div class="w-2/3 text-right text-md font-bold">{{ _product.description }}</div>
          </div>
          <div class="w-full flex flex-row border-b mb-2">
            <span class="w-1/3 font-bold">Suscripcion:</span>
            <div class="w-2/3 text-right text-md font-bold">{{ recurrent ? 'Mensual' : 'Unico' }}</div>
          </div>
          <div class="w-full flex flex-row border-b mb-2">
            <span class="w-1/3 font-bold">Metodo de Pago:</span>
            <div class="w-2/3 text-right text-md font-bold">XXXX-XXXX-XXXX-{{ _card.last4 }}</div>
          </div>
          <div class="w-full flex flex-row border-b mb-2" v-if="_promotionCode">
            <span class="w-1/3 font-bold">Códigos de Promoción</span>
            <div class="w-2/3 text-right text-md font-bold">{{ _promotionCode.code }}</div>
          </div>
          <div class="w-full flex flex-row">
            <span class="w-1/3 font-bold">Total:</span>
            <div class="w-2/3 text-right text-md font-bold">
              $ {{ _promotionCode ? _promotionCode.totalWithPromotionCode : _product.price / 100 }} USD
            </div>
          </div>
          <div v-if="recurrent">
            <span class="w-1/3 font-bold"></span>
            <div class="w-2/3 text-right text-md font-bold color-primary">**Se renovara automaticamente</div>
          </div>
        </div>
        <div class="w-full text-right">
          <el-button
            type="primary" plain
            class="self-start"
            @click="prev">
            Atras
          </el-button>
          <el-button
            class="el-button--primary"
            @click="submitPayment"
            v-loading.fullscreen.lock="loading">
            Pagar
          </el-button>
        </div>
      </content-card>
    </div>
  </el-dialog>
</template>
<style>
.swal2-container {
  z-index: 2500 !important;
}
</style>
<script>
import _ from 'lodash';
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import responsiveSize from '@/mixins/responsiveSize';
import error from '@/mixins/error';
import AdminService from '@/services/AdminService';
import PaymentService from '@/services/PaymentService';
import ContentCard from '@/components/molecules/ContentCard';
import { MembershipEnum } from '@/constants';
import service from "./service";
import { ACCOUNT_REPLACE_MEMBERSHIP } from '@/store/modules/account/mutation-types';
import numbers from '@/utils/numbers';
import CouponsService from '@/services/CouponsService';

const tabs = {
  products: 0,
  cards: 1,
  promotionCodes: 2,
  resume: 3,
};

export default {
  name: 'Payment',
  mixins: [
    error,
    responsiveSize,
  ],
  components: {
    StripeElementCard,
    ContentCard,
  },
  data() {
    return {
      active: tabs.products,
      width: '40%',
      visible: false,
      loading: false,
      addCard: false,
      addPromotionCode: false,
      newPromotionCode: null,
      recurrent: true,
      products: [],
      cards: [],
      promotionCodes: [],
      errorMessage: undefined,
      subscription: undefined,
      purchase: {
        cardId: null,
        productId: null,
        promotionCodeId: null,
        clientSecret: null,
        isAnnual: false,
      },
      stripe: null,
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      tabs: tabs,
    }
  },
  mounted() {
    this.subscription = service.subscribe(this.open);
    window.addEventListener('resize', _.debounce(this.resize, 200));
  },
  destroyed() {
    this.subscription.unsubscribe();
    window.removeEventListener('resize', _.debounce(this.resize, 200));
  },
  computed: {
    _product() {
      return this.products.find((p) => p.id === this.purchase.productId);
    },
    _card() {
      return this.cards.find((p) => p.id === this.purchase.cardId);
    },
    _promotionCode() {
      if (this.purchase.promotionCodeId) {
        const {
          code, isPercent, percentOff, amountOff,
        } = this.promotionCodes.find((p) => p.id === this.purchase.promotionCodeId);
        return {
          code: code,
          totalWithPromotionCode: isPercent
            ? ((this._product.price / 100) - ((this._product.price / 100) * percentOff / 100)).toFixed(2)
            : ((this._product.price / 100) - amountOff).toFixed(2)
        };
      }
      return null;
    },
  },
  watch: {
    'errorMessage': {
      deep: true,
      handler: _.debounce(async function () {
        if (this.errorMessage) {
          await this.$toastr.e(this.errorMessage);
          this.errorMessage = undefined;
        }
      }, 300)
    },
  },
  methods: {
    open() {
      this.visible = true;
      this.stripe = window.Stripe(this.publishableKey);
      this.onMount();
    },
    close() {
      this.visible = false;
      this.onDestroy();
    },
    resize() {
      const percentageWidth = this.getPercentageWidth();
      this.width = percentageWidth === '30%' ? '40%' : percentageWidth;
    },
    prev() {
      let prevTab;
      switch (this.active) {
        case tabs.cards: prevTab = tabs.products; break;
        case tabs.promotionCodes: prevTab = tabs.cards; break;
        case tabs.resume: prevTab = tabs.promotionCodes; break;
        default:
      }
      this.active = prevTab;
    },
    next() {
      let nextTab;
      switch (this.active) {
        case tabs.products: nextTab = tabs.cards; break;
        case tabs.cards: nextTab = tabs.promotionCodes; break;
        case tabs.promotionCodes: nextTab = tabs.resume; break;
        default:
      }
      this.active = nextTab;
      if (this.active === tabs.cards) this.getCards();
      if (this.active === tabs.promotionCodes) this.getPromotionCodes(this.purchase.isAnnual);
    },
    selectProduct(product) {
      this.purchase.productId = this.purchase.productId === product.id ? null : product.id;
      this.purchase.isAnnual = product.isAnnual;
    },
    selectCard(card) {
      this.purchase.cardId = this.purchase.cardId === card.id ? null : card.id;
    },
    selectPromotionCode(promotionCode) {
      if (promotionCode.isUsed) {
        return;
      }
      this.purchase.promotionCodeId = this.purchase.promotionCodeId === promotionCode.id ? null : promotionCode.id;
    },
    async getProducts() {
      try {
        this.products = await PaymentService.products({ recurring: this.recurrent });
        this.purchase.productId = null;
        this.purchase.isAnnual = false;
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      }
    },
    async getCards() {
      try {
        this.cards = await PaymentService.cards();
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      }
    },
    async getPromotionCodes(isAnnual) {
      try {
        this.promotionCodes = await CouponsService.getAccountCoupons(isAnnual);
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      }
    },
    async onMount() {
      await this.resize();
      await this.onDestroy();
      await this.getProducts();
    },
    async onDestroy() {
      this.active = tabs.products;
      this.recurrent = true;
      this.purchase = {
        cardId: null,
        productId: null,
        promotionCodeId: null,
        clientSecret: null,
        isAnnual: false,
      };
      this.newPromotionCode = null;
    },
    async submitCard() {
      this.$refs.stripeElementCardRef.submit();
    },
    async createCard(token) {
      this.loading = true;
      try {
        const card = await PaymentService.createCard({ token: token.id });
        this.cards.push(card);
        this.addCard = false;
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
    async createPromotionCode() {
      this.loading = true;
      try {
        let coupons = [];
        if (this.newPromotionCode) {
          coupons = await CouponsService.check({ PromotionCode: this.newPromotionCode }) || [];
        }
        if (coupons.length > 0 && coupons.find((c) => c.isAnnual === this.purchase.isAnnual)) {
          const coupon = coupons[0];
          await AdminService.MyMembership.createPromotionCode({ couponId: coupon.id });
          this.promotionCodes.push(coupon);
          this.addPromotionCode = false;
        } else {
          this.errorMessage = 'Código de Promoción Invalido';
        }
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
    async createSubscription() {
      try {
        const data = {
          productId: this.purchase.productId,
        };
        if (this.purchase.promotionCodeId) data.couponId = this.purchase.promotionCodeId;
        const { clientSecret } = await PaymentService.createSubscription(data);
        this.purchase.clientSecret = clientSecret;
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      }
    },
    async createPaymentIntent() {
      try {
        await this.stripe
          .confirmCardPayment(this.purchase.clientSecret, { payment_method: this.purchase.cardId })
          .then(async (result) => {
            if (result.error) {
              this.$toastr.e(result.error.message);
            } else {
              await PaymentService.updateMembership({
                productId: this.purchase.productId,
                couponId: this.purchase.promotionCodeId || undefined,
              });
              await this.updateAccountMembership(result.paymentIntent);
              this.close();
              this.$swal("Pago exitoso!", "Tu pago ha sido procesado con exito!", "success");
            }
          });
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      }
    },
    async updateAccountMembership(paymentIntent) {
      const payDate = new Date();
      const endDate = new Date();
      if (this.purchase.isAnnual) endDate.setFullYear(endDate.getFullYear() + 1);
      else endDate.setMonth(endDate.getMonth() + 1);
      // Add a day
      endDate.setDate(endDate.getDate() + 1);
      await this.$store.dispatch(
        ACCOUNT_REPLACE_MEMBERSHIP,
        {
          membershipType: MembershipEnum.Basic,
          membershipEndDate: endDate,
          membershipLastPay: {
            createdAt: payDate,
            object: paymentIntent.object,
            amount: paymentIntent.amount,
            currency: paymentIntent.currency,
            succeeded: paymentIntent.status === 'succeeded',
          },
          membershipAboutToEnd: null,
          membershipLimitReached: false,
        },
      );
    },
    async submitPayment() {
      this.loading = true;
      try {
        await this.createSubscription();
        await this.createPaymentIntent();
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
    moneyFormat(value) {
      const { currencySymbol } = this.$store.state.account;
      return numbers.moneyFormat(value, currencySymbol);
    },
    canAddPromotionCode() {
      if (!this.addPromotionCode && this.promotionCodes.length === 0) {
        this.addPromotionCode = true;
      } else {
        this.$swal(
          'Ya cuentas con cupones',
          'Detectamos que ya se ha usado un cupón antes, contacta a soporte para usar otro cupón',
          'error',
        );
      }
    }
  },
}
</script>
