// import Proxy from '@/proxies/AuthProxy';
import * as types from "./mutation-types";

export const check = ({ commit }) => {
  commit(types.AUTH_CHECK);
};

export const store = ({ commit }, data) => {
  commit(types.AUTH_STORE, { token: data.token });
};

export const logout = ({ commit }) => {
  commit(types.AUTH_LOGOUT);
};

export default {
  check,
  store,
  logout,
};
