import numeral from 'numeral';

export default {
  moneyFormat(value, currencySymbol = '$') {
    return `${currencySymbol} ${numeral(value).format('0,0.00')}` ;
  },
  getAverage(value, total, decimalPlaces = 2) {
    if (!value) {
      return `${(0).toFixed(decimalPlaces)} %`
    }
    return `${(value * 100 / total).toFixed(decimalPlaces)} %` ;
  },
  getRandomNumber(min = 0, max = 999) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  },
};
