import HttpProxy from '@/services/HttpProxy';

export default class PaymentService {
  static async products ({ recurring }) {
    const response = await HttpProxy.submit({
      method: 'get', url: `Payments/Products?recurring=${recurring}`,
    });
    return response?.data ?? null;
  }

  static async cards() {
    const response = await HttpProxy.submit({
      method: 'get', url: 'Payments/Cards',
    });
    return response?.data ?? null;
  }

  static async createCard(data) {
    const response = await HttpProxy.submit({
      method: 'post', url: 'Payments/CreateCard', data,
    });
    return response?.data ?? null;
  }

  static async createSubscription(data) {
    const response = await HttpProxy.submit({
      method: 'post', url: 'Payments/CreateSubscription', data,
    })
    return response?.data ?? null;
  }

  static async updateMembership(data) {
    const response = await HttpProxy.submit({
      method: 'post', url: 'Payments/UpdateMembership', data,
    });
    return response?.data ?? null;
  }

  static async unsubscribe() {
    const response = await HttpProxy.submit({
      method: 'post', url: 'Payments/CancelSubscription', data: {},
    });
    return response?.data ?? null;
  }
}
