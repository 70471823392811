<template>
    <el-input class="phone-country" v-model="model.phone" type="number">
        <el-select slot="prefix" v-model="model.phoneCountryCodeId" placeholder="">
            <el-option v-for="flag in getFlags" :key="flag.id" :value="flag.id" :label="`+${flag.countryCode}`">
                <div class="float-left">
                    <country-flag :country="flag.isoCode.toLowerCase()" />
                    {{  flag.name }}
                </div>
                <div class="float-right">
                    +{{ flag.countryCode }}
                </div>
            </el-option>
        </el-select>
    </el-input>
</template>
<style>
.phone-country {
}
.phone-country > .el-input__inner {
  padding-left: 85px !important;
}
.phone-country > .el-input__prefix {
  width: 75px;
  left: 0px;
}
.phone-country > .el-input__prefix > .el-select > .el-input > input {
  padding-left: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
<script>
export default {
    name: 'SaPhoneCountry',
    props: {
        model: {
            type: Object,
            default: () => ({
                phone: null,
                phoneCountryCodeId: null
            }),
        },
    },
    computed: {
        getFlags() {
            return this.$store.state.catalogs.flags;
        },
    },
}
</script>