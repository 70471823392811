export const NOTIFICATIONS_STORE = 'notifications/store';
export const NOTIFICATIONS_DESTROY = 'notifications/destroy';
export const NOTIFICATIONS_MARK_AS_READ = 'notifications/markAsRead';
export const NOTIFICATIONS_DELETE = 'notifications/delete';

export default {
  NOTIFICATIONS_STORE,
  NOTIFICATIONS_MARK_AS_READ,
  NOTIFICATIONS_DESTROY,
  NOTIFICATIONS_DELETE,
};
