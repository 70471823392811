import HttpProxy from '@/services/HttpProxy';
import store from '@/store';

export default class ChecklistItemsService {
  static async get() {
    const response = await HttpProxy.submit({
      method: 'get', url: 'Checklists',
    });
    store.dispatch('checklistItems/store', response?.data ?? []);
  }

  static async create(data) {
    const response = await HttpProxy.submit({
      method: 'post', url: 'Checklists', data,
    });
    store.dispatch('checklistItems/create', { ...data, id: response?.data?.id });
  }

  static async update(data) {
    await HttpProxy.submit({
      method: 'put', url: `Checklists/${data.id}`, data,
    });
    store.dispatch('checklistItems/update', data);
  }

  static async delete(id) {
    await HttpProxy.submit({
      method: 'delete', url: `Checklists/${id}`,
    });
    store.dispatch('checklistItems/remove', id);
  }
}
