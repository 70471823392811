<template>
  <el-dialog
    :visible.sync="visible"
    :width="width">
    <div class="flex flex-col">
      <div
        class="flex flex-row items-center">
        <span class="text-lg font-bold">Servicio</span>
        <div class="ml-auto">
          <sa-icon-button
            with-bg
            iconClass="text-xl bx bx-x"
            @click="close" />
        </div>
      </div>
      <div class="py-2">
        <el-form
          :model="service"
          ref="serviceForm"
          v-loading="loading">
          <el-form-item prop="name">
            <div class="w-full flex flex-col">
              <span>Servicio</span>
              <el-select v-model="service.name">
                <el-option :value="ServiceEnum.MicroWelding" :label="ServiceEnumDict[ServiceEnum.MicroWelding]" />
                <el-option :value="ServiceEnum.ChargingPin" :label="ServiceEnumDict[ServiceEnum.ChargingPin]" />
                <el-option :value="ServiceEnum.Screen" :label="ServiceEnumDict[ServiceEnum.Screen]" />
                <el-option :value="ServiceEnum.Keyboards" :label="ServiceEnumDict[ServiceEnum.Keyboards]" />
                <el-option :value="ServiceEnum.Cleaning" :label="ServiceEnumDict[ServiceEnum.Cleaning]" />
                <el-option :value="ServiceEnum.Maintenance" :label="ServiceEnumDict[ServiceEnum.Maintenance]" />
                <el-option :value="ServiceEnum.Wet" :label="ServiceEnumDict[ServiceEnum.Wet]" />
                <el-option :value="ServiceEnum.Check" :label="ServiceEnumDict[ServiceEnum.Check]" />
                <el-option :value="ServiceEnum.Battery" :label="ServiceEnumDict[ServiceEnum.Battery]" />
                <el-option :value="ServiceEnum.Other" :label="ServiceEnumDict[ServiceEnum.Other]" />
              </el-select>
            </div>
          </el-form-item>
          <el-form-item prop="description">
            <span>Descripción</span>
            <el-input v-model="service.description" />
          </el-form-item>
          <el-form-item prop="cost">
            <span>Costo</span>
            <el-input v-model="service.cost" />
          </el-form-item>
          <el-form-item prop="price">
            <span>Precio Final</span>
            <el-input v-model="service.price" />
          </el-form-item>
        </el-form>
      </div>
      <div class="flex justify-end flex-row space-x-4">
        <sa-button type="secondary" class="text-right" @click="close">
          Cancelar
        </sa-button>
        <sa-button
          type="primary"
          @click="submit"
          v-loading="loading">
          Guardar
        </sa-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import _ from 'lodash';
import ServiceService from '@/services/ServiceService';
import responsiveSize from '@/mixins/responsiveSize';
import error from '@/mixins/error';
import _service from './service';
import _command, { Commands } from './commands';
import { ServiceEnum, ServiceEnumDict } from '@/constants';

export default {
  name: 'Service',
  mixins: [error, responsiveSize],
  data() {
    return {
      width: '40%',
      loading: false,
      visible: false,
      errorMessage: null,
      subscription: null,
      service: {
        id: null,
        name: null,
        description: null,
        cost: null,
        price: null,
      },
      ServiceEnum,
      ServiceEnumDict,
    }
  },
  mounted() {
    this.subscription = _service.subscribe(this.open);
    window.addEventListener('resize', _.debounce(this.resize, 200));
  },
  destroyed() {
    this.subscription.unsubscribe();
    window.removeEventListener('resize', _.debounce(this.resize, 200));
  },
  watch: {
    'errorMessage': {
      deep: true,
      handler: _.debounce(async function () {
        if (this.errorMessage) {
          await this.$toastr.e(this.errorMessage);
          this.errorMessage = undefined;
        }
      }, 300)
    },
  },
  methods: {
    resize() {
      this.width = this.getPercentageWidth();
    },
    open(payload) {
      this.visible = true;
      this.service = {
        id: payload?.id,
        name: payload?.name,
        description: payload?.description,
        cost: payload?.cost,
        price: payload?.price,
      };
      this.resize();
    },
    close() {
      this.visible = false;
    },
    async validate() {
      if (!this.service.name) {
        return { isValid: false, message: 'Captura el nombre del servicio' };
      } else if (!this.service.cost) {
        return { isValid: false, message: 'Captura el cost del servicio' };
      } else if (!this.service.price) {
        return { isValid: false, message: 'Captura el precio del servicio' };
      } else {
        return { isValid: true };
      }
    },
    async submit() {
      const { isValid, message } = await this.validate();
      if (isValid) await this.save();
      else this.errorMessage = message;
    },
    async save() {
      this.loading = true;
      try {
        if (this.service.id) {
          const saved = await ServiceService.update(this.service.id, this.service);
          this.$toastr.s('Se actualizo con exito');
          _command.onCommand(Commands.update, saved);
          this.close();
        } else {
          const saved = await ServiceService.create(this.service);
          this.$toastr.s('Se creo con exito');
          _command.onCommand(Commands.create, saved);
          this.close();
        }
      } catch(error) {
        this.errorMessage = this.getErrorMessage(error);
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>
