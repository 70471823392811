<template>
  <el-button
    circle
    :icon="icon"
    :type="type"
    :title="title"
    :disabled="disabled"
    @click="onClick()" />
</template>
<script>
export default {
  name: 'SaActionButton',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: () => 'primary',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  }
}
</script>