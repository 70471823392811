<template>
  <el-tooltip
    manual
    :value="show"
    :placement="placement"
    :class="{ 'bg-purple-500': bgPurple, 'rounded-full': roundedFull }"
    :popper-class="popperClass">
    <div slot="content">
      <div class="p-4 bg-purple-500">
        <div class="font-bold mb-2">
          <span class="text-xl">{{ title }}</span>
        </div>
        <div>
          <span class="text-lg">{{ content }}</span>
          <div class="text-lg" v-if="options.length">
            <div v-for="option in options" :key="option">
              <span>- {{ option }}</span>
            </div>
          </div>
        </div>
        <div>
          <div class="text-right">
            <el-button @click="next">Entendido</el-button>
          </div>
        </div>
      </div>
    </div>
    <slot />
  </el-tooltip>
</template>
<style>
.mobile-view {
  width: 80% !important;
}
</style>
<script>
import responsiveSize from '@/mixins/responsiveSize';

export default {
  name: 'QuickStartTip',
  mixins: [responsiveSize],
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Boolean,
      default: () => false,
    },
    placement: {
      type: String,
      required: true,
    },
    roundedFull: {
      type: Boolean,
      default: () => false,
    },
    delay: {
      type: Number,
      default: () => 100,
    },
    bgPurple: {
      type: Boolean,
      default: () => true,
    },
  },
  methods: {
    next() {
      this.$emit('onNextStep');
    },
    resize() {
      const percentageWidth = this.getPercentageWidth();
      this.isMobile = percentageWidth === '90%';
    }
  },
  data() {
    return {
      show: false,
      isMobile: false,
    }
  },
  watch: {
    value(visible) {
      if (visible) {
        setTimeout(() => {
          this.show = true;
        }, this.delay);
      } else {
        this.show = false;
      }
    },
  },
  created() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  computed: {
    popperClass() {
      let classes = 'bg-purple-500 w-1/4 p-0';
      if (this.isMobile) {
        classes = `${classes} mobile-view`;
      }
      return classes;
    }
  }
}
</script>
