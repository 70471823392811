<template>
  <div class="">
    <label class="el-checkbox">
      <span :class="`el-checkbox__input ${checked ? 'is-checked' : ''} ${indeterminate ? 'is-indeterminate' : ''}`">
        <span class="el-checkbox__inner"></span>
        <input type="checkbox" aria-hidden="false" class="el-checkbox__original"
               :checked="checked" @click.stop="onclick(id)" />
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'SaCheckbox',
  props: {
    id: undefined,
    checked: Boolean,
    indeterminate: Boolean,
    onclick: Function,
  },
}
</script>
