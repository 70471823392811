import * as types from './mutation-types';

export const store = ({ commit }, data) => {
  commit(types.CATALOGS_STORE, data);
};

export const destroy = ({ commit }) => {
  commit(types.CATALOGS_DESTROY);
};

export default {
  store,
  destroy,
};
