import {
  ACCOUNT_STORE,
  ACCOUNT_REPLACE_CURRENCY,
  ACCOUNT_REPLACE_MEMBERSHIP,
  ACCOUNT_REPLACE_MEMBERSHIP_ABOUT_TO_END,
  ACCOUNT_REPLACE_PERMITS,
  ACCOUNT_DESTROY,
} from './mutation-types';

export default {
  [ACCOUNT_STORE](state, data) {
    state.id = data.id;
    state.email = data.email;
    state.fullname = data.fullname;
    state.businessId = data.businessId;
    state.accountType = data.accountType;
    state.currencySymbol = data.currencySymbol;
    // MEMBERSHIP
    state.membershipType = data.membershipType;
    state.membershipEndDate = data.membershipEndDate;
    state.membershipLastPay = data.membershipLastPay;
    state.membershipAboutToEnd = data.membershipAboutToEnd;
    state.membershipLimitReached = data.membershipLimitReached;
    // PERMITS
    state.canViewAdministration =  data.canViewAdministration;
    state.canViewBranchOffices =  data.canViewBranchOffices;
    state.canViewStatistics =  data.canViewStatistics;
    state.canViewOrders =  data.canViewOrders;
    state.canEditOrders =  data.canEditOrders;
    state.canEditOrdersPrices =  data.canEditOrdersPrices;
    state.canEditStockQuantities =  data.canEditStockQuantities;
    state.canCreateOrders = data.canCreateOrders;
  },
  [ACCOUNT_REPLACE_CURRENCY](state, data) {
    state.currencySymbol = data.currencySymbol;
  },
  [ACCOUNT_REPLACE_MEMBERSHIP](state, data) {
    state.membershipType = data.membershipType;
    state.membershipEndDate = data.membershipEndDate;
    state.membershipLastPay = data.membershipLastPay;
    state.membershipAboutToEnd = data.membershipAboutToEnd;
    state.membershipLimitReached = data.membershipLimitReached;
  },
  [ACCOUNT_REPLACE_MEMBERSHIP_ABOUT_TO_END](state, data) {
    state.membershipAboutToEnd = data.membershipAboutToEnd;
  },
  [ACCOUNT_REPLACE_PERMITS](state, data) {
    state.canViewAdministration = data.canViewAdministration;
    state.canViewBranchOffices = data.canViewBranchOffices;
    state.canViewStatistics = data.canViewStatistics;
    state.canViewOrders = data.canViewOrders;
    state.canEditOrders = data.canEditOrders;
    state.canEditOrdersPrices = data.canEditOrdersPrices;
    state.canEditStockQuantities = data.canEditStockQuantities;
    state.canCreateOrders = data.canCreateOrders;
  },
  [ACCOUNT_DESTROY](state) {
    state.id = null;
    state.email = null;
    state.fullname = null;
    state.accountType = null;
    state.businessId = null;
    state.businessName = null;
    state.setupCompleted = false;
    state.currencySymbol = '$';
    // MEMBERSHIP
    state.membershipType = null;
    state.membershipEndDate = null;
    state.membershipLastPay = {
      createdAd: null,
      object: null,
      amount: null,
      currency: null,
      succeeded: false,
    };
    state.membershipAboutToEnd = null;
    // PERMITS
    state.canViewAdministration = false;
    state.canViewBranchOffices = false;
    state.canViewStatistics = false;
    state.canViewOrders = false;
    state.canEditOrders = false;
    state.canEditOrdersPrices = false;
    state.canEditStockQuantities = false;
    state.canCreateOrders = false;
  }
};
